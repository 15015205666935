import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { AgentContext } from './AgentContextProvider';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.2rem',
    paddingLeft: '0.4rem',
    backgroundColor: '#4CC6FF',
    fontWeight: 'bold',
    borderRadius: '5px'
  },

  body: {
    padding: '0.5rem'
  }
});

const widgetNames = {
  a: 'CCP'
};

export default function Widget({ id, component: Item, idToken }) {
  const state = useContext(AgentContext);
  const isLoggedIn = state.isLoggedIn;
  const widgets = state.widgets;
  let widgetRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!isLoggedIn) return;
    for (let i = 0; i < widgets.length; i++) {
      widgetNames[widgets[i].i] = widgets[i].displayname;
      setHeight(widgetRef.current.offsetHeight);
      setWidth(widgetRef.current.offsetWidth);
    }
  }, [isLoggedIn]);

  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={12} ref={widgetRef}>
      {widgetNames[id] != null ? (
        <div className={classes.header}>
          <Typography variant="h6">{widgetNames[id]}</Typography>
        </div>
      ) : null}
      <div className={classes.body}>
        <Item height={height} width={width} idToken={idToken} />
      </div>
    </Paper>
  );
}

Widget.propTypes = {
  id: PropTypes.string,
  component: PropTypes.component
};

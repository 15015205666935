import { useEffect, useState } from "react";
import TreeSelect, { FreeSoloNode } from 'mui-tree-select';
import { useDispatch } from 'react-redux'
import { SetDisposition, RemoveDisposition } from '../app/dispositionSlice';
import { TextField } from "@mui/material";
import { filterOptions } from "./utils";

const DropDown = (props) => {
  const dispatch = useDispatch()
  const disposition = props.disposition;
  const shouldReset = props.shouldReset;
  const setShouldReset = props.setShouldReset;

  useEffect(() => {
    if (shouldReset == true) {
      setValue(defaultValue)
      setBranch(defaultBranch)
      setShouldReset(false)
    }
  }, [shouldReset])
  console.info(disposition)
  class Node {
    constructor(value, nodeParent) {
      this.value = value;
      this.nodeParent = nodeParent;
    }

    getParent() {
      const parent = (() => {
        if ("subcategories" in this.value) {
          return null;
        } else if ("subvalues" in this.value) {
          return (
            disposition.find(({ subcategories }) =>
              subcategories.some(({ id }) => id === this.value.id)
            ) || null
          );
        }
        else {
          let subcategories = disposition[0]['subcategories']
          for (let i = 0; i < subcategories.length; i++) {
            let subcategory = subcategories[i]
            let state
            let nodeString = JSON.stringify(this.value)

            if (JSON.stringify(subcategory) == nodeString) {
              state = disposition.find(({ subcategories }) => subcategories.some(({ id }) => id == this.value.id))
              if (state) { return state }

            } else if ('subvalues' in subcategory && subcategory['subvalues'].filter(item => JSON.stringify(item) == nodeString).length > 0) {
              state = subcategory
              if (state) { return state }
            }
          }
          return null;
        }
      })()
      return parent ? new Node(parent) : parent;
    }

    getChildren() {
      if ("subcategories" in this.value) {
        return this.value.subcategories.map((subcategory) => new Node(subcategory, this.value));
      } else if ("subvalues" in this.value) {
        return this.value.subvalues.map((subvalue) => new Node(subvalue, this.value));
      } else {
        return null;
      }
    }
    isBranch() {
      return "subcategories" in this.value || "subvalues" in this.value
    }
    isEqual(to) {
      return to.value.id === this.value.id;
    }
    toString() {
      return this.value.name;
    }
  }

  const [defaultBranch, setDefaultBranch] = useState(() => new Node(disposition[0]));
  const [branch, setBranch] = useState(defaultBranch)
  const [defaultValue, setDefaultValue] = useState(new Node({ name: '(Choose One)', id: '1-0' }))
  const [value, setValue] = useState(defaultValue)

  // Builds Contact Attribute to update in Contact Trace Record
  const onChange = (e, item, reason) => {
    let parent = disposition[0]['name']

    switch (true) {

      case (reason ==='selectOption') && (parent === item['nodeParent']['name']):{
        setValue(item)
        let obj = {}
        obj[parent] = item['value']['name']
        dispatch(SetDisposition(obj))
        break;
      }

      case (reason ==='selectOption') && (item['nodeParent']['name'] === item['value']['name']):{
        setValue(item)
        let obj = {}
        obj[parent] = item['value']['name']
        dispatch(SetDisposition(obj))
        break;
      }

      case (reason ==='selectOption') && (item['nodeParent']['name'] !== item['value']['name']):{
        setValue(item)
        let obj = {}
        obj[parent] = item['nodeParent']['name'] + ' - ' + item['value']['name']
        dispatch(SetDisposition(obj))
        break;
      }

      case reason === 'clear':{
        dispatch(RemoveDisposition(parent))
        setValue(defaultValue)
        setBranch(defaultBranch)
      }

    }
  }
return (
  <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
    <TreeSelect
      value={value}
      defaultValue={defaultValue}
      defaultBranch={defaultBranch}
      branch={branch}
      onBranchChange={(_, branch) => setBranch(branch)}
      isBranch={(node) => node.isBranch()}
      getChildren={(node) => node ? node.getChildren() : disposition.map((category) => new Node(category))}
      getParent={(node) => node.getParent()}
      getOptionDisabled={(option) => {
        let _a;
        return (
          option.isBranch() &&
          !((_a = option.getChildren()) === null
            ? void 0
            : _a.length)
        );
      }}
      filterOptions={(options, inputValue) => filterOptions(options, inputValue)}
      style={{ width: '100%', height: '400' }}
      isOptionEqualToValue={(option, value) => {
        return option instanceof FreeSoloNode ? false : option.isEqual(value);
      }}
      onChange={(e, value, reason) => onChange(e, value, reason)}
      renderInput={(params) => {
        return(
        <TextField
          {...params}
          label={disposition[0]['name']}
        />)
      }}
      sx={{ mb: 2 }}
    />
  </div>);
}
export default DropDown;

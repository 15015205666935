import React, { memo, useCallback, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import { ContactContext } from '../ContactContextProvider'
import { useConnected, useDestroy } from '../../hooks.js'


import ui from '../ui.js';

const ContactAttributes = () => {
  const contactstate = useContext(ContactContext)
  var attributefields = window.attributes


    const setPrimaryID = (attrobj) => {
    let newObj = { ...contactstate, ...attrobj }
    delete newObj['setOnContact']
    let listArgs = Object.values(newObj).map(val => { return val })

    contactstate.setOnContact(...listArgs)
  }




  //Hook for behavior when Contact is connected
  const onConnected = useCallback((c) => {
    try {
      const attr = c.getAttributes();


      // If contact is a phone call

      var findqueue = attributefields.find(o => o.label === 'Queue')
      if (findqueue) {
        ui.setText("contact_queueArn", c.getQueue().name)
      }

      for (let i = 0; i < attributefields.length; i++) {
        if (attributefields[i].label !== 'Queue') {
          if (attr[attributefields[i].name] !== undefined) {
            ui.setText(attributefields[i].id, attr[attributefields[i].name].value)
          } else {
            ui.setText(attributefields[i].id, '')
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, []);
  useConnected(onConnected);



  const onDestroy = useCallback(() => {

    var findqueue = attributefields.find(o => o.label === 'Queue')
    if (findqueue) {
      ui.setText("contact_queueArn", '')
    }

    for (let i = 0; i < attributefields.length; i++) {
      if (attributefields.label !== 'Queue') {
        ui.setText(attributefields[i].id, '')
      }
    }
    setPrimaryID({ primarycontactid: '', onContact: false })



  }, []);
  useDestroy(onDestroy);



  function setFields(attrFields) {




    return attrFields.map((attr, i) => {
      if (attr.label !== 'Queue') {

        if (attr.type === 'dropdown') {
          return <TextField style={{ margin: '3px'}}
          key={attr.id}
            select
            className='not_draggable'
            id={attr.id}
            label={attr.label}
          />

        } else {
          return <TextField
            key={attr.id}

            disabled={true}
            InputLabelProps={{ shrink: true, }}
            className='not_draggable'
            id={attr.id}
            label={attr.label}
            fullWidth
          />
        }
      }

    })

  }

  return (
    <div className='attributesContainer'>



      <div className='attribute'>

        {/* Create Queue Field to ensure it's on top and full width*/}
        <TextField
          key='contact_queueArn'
          className='not_draggable'
          id='contact_queueArn'
          label='Queue'
          disabled={true}
          fullWidth
          InputLabelProps={{ shrink: true, }}
        />
        {setFields(attributefields)}
      </div>


    </div>
  )



}

export default memo(ContactAttributes);

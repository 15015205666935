import { configureStore} from '@reduxjs/toolkit';
import dispositionReducer from './dispositionSlice';

export const store = configureStore(
  {
    reducer: {
      disposition: dispositionReducer,
    },
  });

export const AppDispatch = store.dispatch;
export const RootState = store.getState;


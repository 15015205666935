import React, { useContext } from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import session from './session.js';
import ContactList from './ContactList/ContactList';
import { AgentContext } from './AgentContextProvider';
import logo from '../images/CHC_Logo_Blue_Red.png';
import 'amazon-connect-streams';
import { connectName } from '../config.js';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: '99%',
    display: 'flex'
  },
  logo: {
    margin: 'auto'
  }
}));


export default function TopBar() {
  const classes = useStyles();
  const state = useContext(AgentContext);
  const isLoggedIn = state.isLoggedIn;
  const connect = window.connect;

  async function logout() {
    session.agent.setState(
      session.agent.getAgentStates().find((state) => state.name === 'Offline'),
      {
        success: function () {
          state.setIsLoggedIn(false, '', [], [], [], [], '');
          fetch('https://' + connectName + '.my.connect.aws/connect/logout', {
            credentials: 'include',
            mode: 'no-cors'
          }).then(() => {
            const eventBus = connect.core.getEventBus();
            eventBus.trigger(connect.EventType.TERMINATE);
          });
          connect.core.terminate();
        },
        failure: function (err) {
          console.error(err);
        }
      }
    );
  }

  return (
    <Card className={classes.root}>
      <div className={classes.logo}>
        <img src={logo} width={140} height={40} alt="logo" />
      </div>
      {isLoggedIn ? (
        <>
          <ContactList />
          <IconButton aria-label="save" onClick={logout}>
            <LogoutIcon />
          </IconButton>
        </>
      ) : null}
    </Card>
  );
}

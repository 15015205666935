
import axios from 'axios';
import {api_url} from '../../../config.js';
import {auth_url} from '../../../config.js';
import { handleResponse, handleError} from './response'; 
var accesstoken ='';
const put=(resource,model)=>{
    
    return axios
        .put(api_url+'/'+ resource, model, {'headers':{'Authorization': accesstoken}})
        .then(handleResponse)
        .catch(handleError)
}

const get=(resource,model)=> {
    return axios    
        .get(api_url+'/'+ resource, {'headers':{'Authorization': accesstoken}, 'params':model})
        .then(handleResponse)
        .catch(handleError)
}

const authpost =(resource, model)=>{

    return axios
        .post(auth_url+'/'+resource,{}, {'headers': { 'Content-Type': 'application/x-www-form-urlencoded' },'params':model})
        .then((res)=>{
           accesstoken = res.data.id_token;
           return res
        })
        .catch(handleError)
}


export const apiProvider = { 
    get, 
    put
  };

export const authProvider = {
    authpost
}
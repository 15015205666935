// core.js

import {apiProvider} from './provider';
import {authProvider} from './provider'

export class ApiCore {
  constructor(options) {
    if (options.get) {
      this.get = (model, headers) => {
        return apiProvider.get(options.url, model, headers);
      };
    }


    if (options.put) {
      this.put = (model, headers) => {
        return apiProvider.put(options.url, model, headers);
      };
    }

    if (options.authpost){
      this.authpost=(model)=>{
        return authProvider.authpost(options.url, model);
      }
    }
  }




}
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

// SPDX-License-Identifier: MIT-0

import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import 'amazon-connect-streams';
import '../css/styles.css';

// Widgets
import Widget from './Widget';
import CustomCCP from './CustomCCP';

import { withSize } from 'react-sizeme';
import { AgentContext } from './AgentContextProvider';
import TopBar from './TopBar';
import { widgetmap } from '../widgets.js';

const componentList = {
  a: CustomCCP
};

const Dashboard = (props) => {
  const state = useContext(AgentContext);
  const isLoggedIn = state.isLoggedIn;
  const widgets = state.widgets;
  const size = props.size;
  const idToken = props.idToken

  function mapwidgets(widgetname) {
    for (var key in widgetmap) {
      if (key === widgetname) {
        return widgetmap[key];
      }
    }
  }

  const originalItems = [{ w: 3, h: 30, x: 0, y: 0, i: 'a', static: true }];

  const [items, setItems] = useState(originalItems);
  const width = size.width;

  const onRemoveItem = (item) => {
    for (let i = 0; i < items.length; i++) {
      if (item === items[i]['i']) {
        setItems(items.filter((i) => i.i !== item));
      }
    }
  };

  const onAddItem = (item) => {
    for (let i = 0; i < originalItems.length; i++) {
      if (item === originalItems[i]['i']) {
        setItems([...items, originalItems[i]]);
      }
    }
  };

  function getLayout(key) {
    for (let i = 0; i < items.length; i++) {
      if (key === items[i]['i']) {
        return items[i];
      }
    }
  }

  useEffect(() => {
    for (let i = 0; i < widgets.length; i++) {
      componentList[widgets[i].i] = mapwidgets(widgets[i].name);
    }

    for (let i = 0; i < widgets.length; i++) {
      originalItems.push({
        w: parseInt(widgets[i].w),
        h: parseInt(widgets[i].h),
        x: parseInt(widgets[i].x),
        y: parseInt(widgets[i].y),
        i: widgets[i].i,
        static: JSON.parse(widgets[i].static)
      });
    }

    setItems(originalItems);
  }, [isLoggedIn]);

  return (
    <>
      {isLoggedIn ? (
        <TopBar
          items={items}
          onRemoveItem={onRemoveItem}
          onAddItem={onAddItem}
          isLoggedIn={isLoggedIn}
          originalItems={originalItems}
        />
      ) : null}

      <ResponsiveGridLayout
        className="layout"
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 13, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={10}
        width={width}
        draggableCancel=".not_draggable"
        isBounded={true}>

        {items.map((key) => (
          <div className="widget" key={key.i} data-grid={getLayout(key.i)}>
            <Widget id={key.i} onRemoveItem={onRemoveItem} component={componentList[key.i]} idToken={idToken} />
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
};

export default withSize({ refreshMode: 'debounce', refreshRate: 60 })(Dashboard);

Dashboard.propTypes = {
  size: PropTypes.number
};

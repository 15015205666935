import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const ContactContext = React.createContext({
  onContact: false,
  status: '',
  setOnContact: () => {},
  attributes: {},
  contactid: [],
  primarycontactid: ''
});

export const ContactContextProvider = (props) => {
  const setOnContact = (onContact, status, attributes, contactids, primarycontactid) => {
    setState({
      ...state,
      onContact: onContact,
      status: status,
      attributes: attributes,
      contactids: contactids,
      primarycontactid: primarycontactid
    });
  };

  const initState = {
    onContact: false,
    status: '',
    setOnContact: setOnContact,
    attributes: {},
    contactids: [],
    primarycontactid: ''
  };

  const [state, setState] = useState(initState);

  return <ContactContext.Provider value={state}>{props.children}</ContactContext.Provider>;
};

ContactContextProvider.propTypes = {
  children: PropTypes.props
};


export function handleResponse(response){
    if(response.results){
        return response.results;
    }

    if(response.data){
        return response.data;
    }

    if(response.status == 200){
      return response
    }
}

export function handleError(error){
    if(error.data){
        console.error(error)
        return error.data;
    }
    return error;
}

export const filterOptions = (options, { inputValue }) =>
  options.filter((option) => {
    const searchDescendants = (node) => {
      const children = node.getChildren();
      if (children?.length) {
        return children.some((cNode) => {
          return (
            cNode.value.name
              .toLowerCase()
              .indexOf(inputValue.toLowerCase()) === 0 ||
            searchDescendants(cNode)
          );
        });
      }
      return false;
    };

    return (
      option.value.name
        .toLowerCase()
        .indexOf(inputValue.toLowerCase()) > -1 ||
      searchDescendants(option)
    );
  });

  export const sortByKey = (list,direction, searchKey) =>{
    switch(direction){

    case "asc":
      return list.sort((a,b)=>{

        return a[searchKey] -b[searchKey]})

    case "desc":
      return list.sort((a,b)=> b[searchKey] - a[searchKey])
  }};

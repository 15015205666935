export const appSettings = {
  connectInstanceUrl: 'https://con-aws-ue1-networkssa-dev-appdev-connect.my.connect.aws',
  connectInstanceId: 'daf2d4c6-fd5e-42cd-bd15-41b217b9a48d',
  connectFederatedCcpUrl: 'https://us-east-1.console.aws.amazon.com/connect/federate/',
  contactAttributesPrefix: 'cc-',
  pingOneSamlUrl:
    'https://saml.emdeon.com/idp/startSSO.ping?PartnerSpId=chcdevconnect%3Aamazon%3Awebservices'
};
//https://saml.emdeon.com/idp/startSSO.ping?PartnerSpId=chcdevconnect%3Aamazon%3Awebservices&TargetResource=https://us-east-1.console.aws.amazon.com/connect/federate/daf2d4c6-fd5e-42cd-bd15-41b217b9a48d?destination=%2Fconnect%2Fccp-v2
//SMS DEV
//"https://dbnaaercap066.dev.healthcareit.net/external/rightnow.jsp?rightnow_auth_id=760Q1IPZBM7238GN
//&TargetResource=

import { createSlice } from '@reduxjs/toolkit'
export const dispositionSlice = createSlice({
  name: 'disposition',
  initialState: {},
  reducers:{
    SetDisposition: (state, action) =>({...state, ...action.payload}),
    RemoveDisposition: (state, action) => {
      const newObj = {...state}
      delete newObj[action.payload]
      return newObj

    },
    ResetDisposition: ()=>({...dispositionSlice.initialState })
}
})

export const GetDisposition = (state) => state.disposition
export const { SetDisposition, ResetDisposition, RemoveDisposition} = dispositionSlice.actions

export default dispositionSlice.reducer

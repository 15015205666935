import session from './session.js';
import ui from './ui.js';

export default function (agent) {
  window.clearTimeout(window.connectTimeout);
  ui.hide('loadingContainer');
  ui.hide('ccpContainer');
  ui.hide('callAttributesContainer');
  window.clearTimeout(window.vfConnectTimeout);
  ui.show('ccpContainer');

  session.agent = agent;

  // const state = useContext(AgentContext)

  // agent.onRefresh(handleAgentRefresh);
  // agent.onRoutable(handleAgentRoutable);
  // agent.onNotRoutable(handleAgentNotRoutable);
  // agent.onOffline(handleAgentOffline);
  // agent.onStateChange(handleAgentStateChange);

  const w = window.open('', window.connect.MasterTopics.LOGIN_POPUP);
  if (w) {
    w.close();
  }
  // function handleAgentRefresh(agent) {
  //   // console.log(logStamp(`Agent is refreshed. Agent status is [${agent.getStatus().name}]`));
  //   session.agent = agent;
  //   window.agent = agent;
  // }

  // function handleAgentRoutable(agent) {
  //   // console.log(logStamp(`Agent is routable. Agent status is [${agent.getStatus().name}]`));
  // }

  // function handleAgentNotRoutable(agent) {
  //   // console.log(logStamp(`Agent is online, but not routable. Agent status is [${agent.getStatus().name}]`));
  // }

  // function handleAgentOffline(agent) {
  //   // console.log(logStamp(`Agent is offline. Agent status is [${agent.getStatus().name}]`));
  // }

  // function handleAgentStateChange(agent) {
  //   /* if (agent.oldState === "Offline" && agent.newState === "Available") {
  //           //ui.show("cisco-dial-out-form");
  //           //ui.addListItem("dials");
  //           // const form = document.getElementById('cisco-dial-out-form');
  //           // form.addEventListener('submit', DialOutCisco);
  //       }*/
  // }

  //agentEvent(agent);
}

// Only used for TES to get the data from the TES PAC Bot

import { ApiCore } from '../api/utilities/core';
const url = 'getexternaldata';
const apiGetExternalData = new ApiCore({
  get: false,
  put: true,
  url: url
});

export { apiGetExternalData };

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './css/index.css';

import { Provider } from 'react-redux'
import  { store } from '../src/components/app/store'
ReactDOM.render(

    <Provider store={store}>
    <App />
    </Provider>
    , document.getElementById('root')
);

serviceWorker.unregister();

import AWS from "aws-sdk"
import "amazon-connect-streams";
import "amazon-connect-chatjs";
import React, { memo, useRef, useEffect, useContext, useCallback, useState } from "react";
import { AgentContext } from './AgentContextProvider'
import { genLogger } from "../lib.js";
import subscribeToAgentEvents from './agentEvents.js';
import { loginUrl, ccpUrl, api_url, apireq } from '../config.js';
import { apiCCPConfig } from '../services/models/ccpconfig.js';
import Alert from "./assets/alert.mp3"
import Icon from "./assets/chc-icon.png"


const connect = window.connect;
const agent = connect.agent;


const name = "CustomCCP";
const { log, error } = genLogger(name);

export let idToken
const CustomCCP = (props) => {
  const ref = useRef();
  const state = useContext(AgentContext)
  const isLoggedIn = state.isLoggedIn
  let token = useRef(null)
  idToken = state.idToken

  useEffect(()=>{
    token.current = props.idToken
    console.info(props.idToken)
  },[props.idToken])

  const invokeMethod = (val, username, queues, channels, attributes, widgets, routingProfile, idToken) => {

    state.setIsLoggedIn(val, username, queues, channels, attributes, widgets, routingProfile, idToken)
  }

    function notifyMe() {
        if (Notification.permission !== 'granted')
            Notification.requestPermission();
        else {
            var notification = new Notification('Amazon Connect Chat', {
                icon: Icon,
                body: 'You have a new chat message',
            });
            notification.onclick = function () {
                window.focus();
            };
        }
    }

  function getNumberOfDays() {
    let contacthistory = (JSON.parse(localStorage.getItem('ContactHistory')) ? JSON.parse(localStorage.getItem('ContactHistory')) : [])

    //If contact history is not an empty array, remove any contacts that are oldler than today
    if (contacthistory != []) {
      let today = new Date();
      let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      for (var i = contacthistory.length - 1; i >= 0; i--) {
        if (date != contacthistory[i]['date']) {
          contacthistory.splice(i, 1);
        }
      }
      localStorage.setItem("ContactHistory", JSON.stringify(contacthistory));

    } else {
      //If contact is an empty array do nothing
      return
    }
  }


  async function getCcpConfig(routingProfile) {

        try {
         let response = await apiCCPConfig
          .put({
            routingProfile: routingProfile
          })

          const data = await response;
          return data;

    } catch (e) {
      console.error(e)
      return e
    }
  }



  useEffect(() => {

    try {
      log("init start");
      if (typeof window == "undefined") throw new Error("Window missing");
      if (typeof window.connect === "undefined")
        throw new Error("Global Connect parameter missing");

      window.connect.core.initCCP(ref.current, {
        ccpUrl: ccpUrl,
        loginPopup: true,
        loginPopupAutoClose: true,
        loginUrl: loginUrl,
        region: "us-east-1",
        pageOptions: {
          enableAudioDeviceSettings: true,
          enablePhoneTypeSettings: true,
        },
        softphone: { allowFramedSoftphone: true },
      }

        );
        connect.contact(function (contact) {
            const c = contact;
            if (contact.getType() !== connect.ContactType.CHAT) {
                return;
            }
            c.onConnecting(function (c) {
                console.info("incoming");
            });
            c.onAccepted(async () => {
                if (contact.getAgentConnection().getMediaType() === connect.MediaType.CHAT) {
                    contact.getAgentConnection().getMediaController()
                        .then(controller => {
                            controller.onMessage(function (response, contentType) {
                                if (response.data.ParticipantRole === 'CUSTOMER') {
                                    notifyMe()

                                }
                            })
                        })
                }
            })
        });

      connect.agent(subscribeToAgentEvents);
      var eventBus = connect.core.getEventBus();
      eventBus.subscribe(connect.AgentEvents.INIT, async function (agent) {
        try {
          const username = agent.getConfiguration().username;
          let routingProfile = agent.getRoutingProfile();
          let queues = routingProfile.queues.filter(items => items.name !== null);
          let rawchannels = agent.getChannelConcurrency();
          let channels = Object.entries(rawchannels).filter(([key, value]) => value > 0).map(([k]) => k);
          let ccpConfig = await getCcpConfig(routingProfile.name);
          let widgets = await ccpConfig.ccpWidgets;
          window.attributes = await ccpConfig.ccpAttributes;
          getNumberOfDays();
          invokeMethod(true, username, queues, channels, window.attributes, widgets, routingProfile.name, token.current);
        } catch (e) { console.error(e) }
      });

    } catch (e) {
      error(e);
    }
  },[]);


  return (
    <>
      <div ref={ref} style={{ width: "98%", height: "500px" }}>
      </div>

    </>
  );

};


export default memo(CustomCCP);

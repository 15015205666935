import React, { useCallback, useState } from "react";
import Modal from '@mui/material/Modal';
import IconButton from "@material-ui/core/IconButton";
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import CloseIcon from '@mui/icons-material/Close';
import MainTable from "../common/MainTable";
import Paper from '@mui/material/Paper';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { useConnected } from '../../hooks.js'

import './contactlist.css';


const style = {
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    margin: '5px',
    backgroundColor: 'red',
    fontWeight: 'bold',
    borderRadius: '5px'
  },
  body: {
    padding: 5,
    height: '80%',
    width: '80%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  },
  modalHeader:{
    backgroundColor: '#4CC6FF',
    color: 'black',
    borderRadius: '5px'
  }
};




export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
 
  const [contactList, setContactList] = useState(JSON.parse(localStorage.getItem('ContactHistory')) || [])





  const onConnected = useCallback((c) => {
    try {
      let today = new Date()
      let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      let contactid = c.getContactId()
      let queue = c.getQueue().name
      let contacts = JSON.parse(localStorage.getItem('ContactHistory')) || []
      contacts.push({ 'contactid': contactid, 'queue': queue, 'date': date, 'time': showTime() })
      localStorage.setItem("ContactHistory", JSON.stringify(contacts))
      setContactList(contacts)
      
    } catch (e) {
      console.error(e)
    }

  }, []);
  useConnected(onConnected);



  function showTime() {
    const date = new Date();
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds();
    const str = [hh, mm, ss].map(x => `${x}`.padStart(2, '0')).join(':')+'Z';
    return str
  }
  

  const columns = [
    { id: 'contactid', label: 'Contact ID', minWidth: 170 },
    { id: 'queue', label: 'Queue', minWidth: 170 },
    { id: 'date', label: 'Date', minWidth: 170 },
    { id: 'time', label: 'Time', minWidth: 170 }

  ]
  return (
    <div>
 
      <IconButton aria-label="save" onClick={handleOpen}>
        <ViewListRoundedIcon />
      </IconButton>
     

      
      <Modal
        style={style}
        open={open}
        onClose={handleClose}
      >
         <Paper style={style.body}>
         <AppBar style={style.modalHeader} position="static">
        <Toolbar>

  
  
          <Typography variant="h6" 
            component="div" sx={{ flexGrow: 1 }}>
            Contacts Handled Today
          </Typography>


      <div className='closeIcon'> 
      <IconButton aria-label="save" onClick={handleClose}>
      <CloseIcon />
      </IconButton>
      </div>
      
      
      
     
        </Toolbar>
      </AppBar>
      <MainTable id="contacttable" rows={contactList} columns={columns} maxheight={"98%"} />
      </Paper>
      </Modal>
    
     
    </div>
  );

}
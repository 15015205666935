import Queue from './components/Queue';
import ContactAttributes from './components/ContactAttributes/ContactAttributes';
import AgentBoard from './components/AgentBoard';
import NfmDss from './components/NfmDss';
import CallDisposition from './components/CallDisposition/CallDisposition';
import TesContactAttributes from './components/TesContactAttributes';
import LegacyCallDisposition from './components/LegacyCallDisposition/LegacyCallDisposition';

export const widgetmap = {
  Queue: Queue,
  ContactAttributes: ContactAttributes,
  AgentBoard: AgentBoard,
  NfmDss: NfmDss,
  CallDisposition: CallDisposition,
  LegacyCallDisposition: LegacyCallDisposition,
  TesContactAttributes: TesContactAttributes
};

import { memo, useCallback } from 'react';
import { useConnected } from '../hooks.js';

const NfmDss = () => {
  // Hook for behavior when Contact is connected
  const onConnected = useCallback((c) => {
    try {
      const attr = c.getAttributes();
      const url = attr['url'].value;
      if (url != null) {
        window.open(url);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);
  useConnected(onConnected);

  return null;
};

export default memo(NfmDss);
